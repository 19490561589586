import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function MainHeader() {
  const [color, setColor] = useState(false);

 

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
const scrolltoSection = (sectionID) =>{
  const section = document.getElementById(sectionID);
  if(section){
    section.scrollIntoView({ behavior: 'smooth' });
  }

}
  useEffect(() => {
    window.addEventListener('scroll', changeColor);
    return () => {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);

  return (
    <div className={color ? 'mainheader mainheader-bg' : 'mainheader'}>
      <div className='fount-logo'>
      <Link to='/'> <img src='whitelogo.png' className='header-logo' alt='logo'></img> </Link>
      </div>
     
      <div className='fount-options'>
        <p className='option' onClick={()=>scrolltoSection('about-us')}> About Us </p>
        <p className='option' onClick={()=>scrolltoSection('our-products')}> Our Products</p>
        <p className='option' onClick={()=>scrolltoSection('testimonials')}> Testimonials </p>
        <p className='option' onClick={()=>scrolltoSection('contact-us')}> Contact Us</p>
      </div>
      
    </div>
  );
}

export default MainHeader;
