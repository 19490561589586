import React, { useState } from 'react'
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
import { useLogin } from '../hooks/useLogin';
function Login() {
  const[email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const {login} = useLogin();
  useEffect(()=>{
    Aos.init({ duration: 2000})
  })
  const handleLogin = async(e) =>{
    e.preventDefault();
    if(!email || !Password){
      alert('Please Fill out all fields');
      return
    }
    await login(email, Password);
   
  }
  return (
    <div className='signup-container'>
  <div data-aos="fade-in" className='top-slogan'>
      <h1 className='signup-title'>Start Shopping <span className='bold'> With an Account</span></h1>
      <p className='signup-slogan'>creating an account ensures that your information can be protected online!</p>
      </div>
      <form onSubmit={handleLogin} data-aos='fade-up'  className='signup-form'>
        <h1 className='signup'> Login</h1>
        
        <div className='input-field'>
        <label className='label'>Email</label>
        <input onChange={(e) => setEmail(e.target.value)} className='input-signup'></input>
        </div>
        <div className='input-field'>
        <label className='label'>Password</label>
        <input onChange={(e) => setPassword(e.target.value)} type='password' className='input-signup'></input>
        </div>
        <button className='signup-button'>Login</button>
        <p className='account-already'> forgot password?</p>
       <Link to='/signup' className='link'> <p className='guest'> create an account?</p></Link>
        <div className='bar'></div>
        <h2 className='or'>or</h2>
       <Link className='link' to='/buy'> <div className='guest-continue'>Continue As Guest</div> </Link>
      </form>
      <img data-aos='fade-down'  src='jarbackground.JPG' className='jar-background' alt='jar-background'></img>
    </div>
  )
}

export default Login