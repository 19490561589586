import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackendHeader from '../components/BackendHeader';
import BackendNavbar from '../components/BackendNavbar';


function Messages() {
const [data, setData] = useState([]);
const [error, setError] = useState('')

useEffect(()=>{
  const authentication = localStorage.getItem('token');
  if(!authentication){
    window.location.href = '/back-login'
  }
}, [])

useEffect(()=>{
fetchData()
}, [])
const fetchData = async () =>{
  const response = await fetch('https://fount.onrender.com/get-all-messages');
  if(!response.ok){
    setError('error fetching data');
    return
  }
  else{
    const jsonData = await response.json();
    setData(jsonData)
  }
    


}

 

return (
  <div className='product-page'>
    <BackendHeader />
    <BackendNavbar />
    <h2>Messages</h2>
    <div className='table-transaction'>
      <div className='table-header'>
        <p className='table-stuff'>First Name</p>
        <p className='table-stuff'>Last Name</p>
        <p className='table-stuff'>Email</p>
        <p className='table-stuff'>Message</p>
      </div>
      {data.length === 0 ? (
        <p className='table-stuff-low'>No data available</p>
      ) : (
        data.map((entry, index) => (
          <Link to={`/message-details/${entry.id}`} className='table-info'>  <div key={index} className='table-info'>
            <p className='table-stuff-low'>{entry.firstName}</p>
            <p className='table-stuff-low'>{entry.lastName}</p>
            <p className='table-stuff-low'>{entry.email}</p>
            <p className='table-stuff-low'>{entry.questions}</p>
          </div>
          </Link>
        ))
      )}
                {error && <p className='error '>{error}</p>}

    </div>
  </div>
);
        };


export default Messages;
