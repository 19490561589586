import React, { useState } from 'react'
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
import { useSignup } from '../hooks/useSignup';
function Signup() {
  const [Name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const {signup} = useSignup();
  useEffect(()=>{
    Aos.init({ duration: 2000})
  })
  const handleSubmit = async(e) =>{
    e.preventDefault();
    if(!Name || !email || !Password){
      alert('please fill all fields');
      return
    }
    else{
    await signup(Name, email, Password)
    }
  }
  return (
    <div className='signup-container'>
      <div data-aos="fade-in" className='top-slogan'>
      <h1 className='signup-title'>Start Shopping <span className='bold'> With an Account</span></h1>
      <p className='signup-slogan'>creating an account ensures that your information can be protected online!</p>
      </div>
      <form onSubmit={handleSubmit} data-aos='fade-up'  className='signup-form'>
        <h1 className='signup'> Signup</h1>
        <div className='input-field'>
        <label className='label'>Full Name</label>
        <input onChange={(e)=>setName(e.target.value)} className='input-signup'></input>
        </div>
        <div className='input-field'>
        <label className='label'>Email</label>
        <input onChange={(e)=>setEmail(e.target.value)} className='input-signup'></input>
        </div>
        <div className='input-field'>
        <label className='label'>Password</label>
        <input onChange={(e)=>setPassword(e.target.value)} type='password' className='input-signup'></input>
        </div>
        <button type='submit' className='signup-button'> Sign Up</button>
        <Link to='/login' className='link'><p className='account-already'> Already Have an Account?</p></Link>
      </form>
      <img data-aos='fade-down'  src='jarbackground.JPG' className='jar-background' alt='jar-background'></img>
    </div>
  )
}

export default Signup