import React from 'react'

function BackgroundImage() {
  return (
    <div className='backgroundImage'>
<img src='jarbackground.JPG' className='background' alt='background'></img>
    </div>
  )
}

export default BackgroundImage