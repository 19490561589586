import React from 'react'
import { Link } from 'react-router-dom';


function BuyHeader() {
 
  
  return (
    <div className="mainheader-bg">
      <div className='fount-logo'>
      <Link to='/'> <img src='whitelogo.png' className='header-logo' alt='logo'></img> </Link>
      </div>
      <div className='fount-options'>
       
      </div>
     
    </div>
  )
}

export default BuyHeader