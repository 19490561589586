import { useAuthContext } from './useAuthContext'

export const useSignup = () => {

  const { dispatch } = useAuthContext()

  const signup = async (Name, email, Password) => {

    const response = await fetch('https://fount.onrender.com/signup-user', {
      method: 'POST',
      headers: {'Content-Type': 'application/json',  'Access-Control-Allow-Origin': '*'},
      body: JSON.stringify({Name, email, Password })
    })
    const json = await response.json()

    if (!response.ok) {
      alert(json.message);
      return;

    }
    if (response.ok) {
      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(json))

      alert('Successfully Created User')

      // update the auth context
      dispatch({type: 'LOGIN', payload: json})

      // update loading state
      window.location.href = '/buy'
    }
  }

  return { signup }
}