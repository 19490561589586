import React, { useState, useEffect } from 'react'

function BackendHeader() {
  const [date,setDate] = useState("");
  const formatDate = (date) => {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };
  const logout = () =>{
    localStorage.clear();
    window.location.href='/back-login'
  }
  useEffect(() => {
    const today = formatDate(new Date());
    setDate(today);
  }, []);
  return (
    <div className='backendHeader'>
      <div className='left'>
      <img src='/blacklogo.png' className='logo-top' alt='logo'></img>
      <div className='welcome'>
        <h3 className='noah'> Welcome Noah</h3>
        <p>{date}</p>

      </div>
      </div>
      <div className='logout'>
        <button onClick={logout} className='logout-button'>Logout</button>
      </div>

    </div>
  )
}

export default BackendHeader