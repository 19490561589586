import {  useEffect, useState } from 'react'
import React from 'react'
import BuyHeader from '../components/BuyHeader'
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
function BuyPage() {
  useEffect(()=>{
    Aos.init({ duration: 2000})
  })
  const [quantity, setQuantity] = useState(1);
  const [finalAmmount, setfinalAmmount] = useState(0);
  const [showMaxReached, setShowMaxReached] = useState("");
  const [shipping, setShipping] = useState(0);
  const [price, setPrice] = useState(15)
  const [cost, setCost] = useState(0);
  // eslint-disable-next-line 
  const [shippingAddress, setShippingAddress] = useState({
    name: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });

  const itemName = "Fount Hair Butter";

  const handleIncreaseQuantity = () => {
    if (quantity < 3) {
      setQuantity(quantity + 1);
    } else {
      setShowMaxReached("Max Amount Reached");
      setTimeout(() => {
        setShowMaxReached(false);
      }, 4000);
    }
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };


 useEffect(()=>{
  const calculatePrice = () =>{
    
    if(quantity === 1){
      setShipping(6.73)
      setCost(15)
    
    }
    if(quantity === 2){
      setShipping(11.98)
      setCost(25)
      setPrice(12.50)

    }
    if(quantity === 3){
      setShipping(14.73)
      setCost(34.80)
      setPrice(11.60)
    }
    setfinalAmmount(cost + shipping);
  }
  calculatePrice();
 },[quantity, cost, shipping])

 const handleCheckout = async () => {
      try{
        const res = await fetch("https://fount.onrender.com/create-checkout-session", {
          method: 'POST',
          headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          mode:'cors',
          body:JSON.stringify({
            items:[
              {
                id:1,
                quantity: quantity,
                name: itemName
              }
            ],
            shippingAddress: shippingAddress,

          })
        })
        const data = await res.json();
        window.location = data.url;
      }
      catch(error){
        console.log(error);
      }
  
};

  return (
    <div className='buypage'>
      <BuyHeader />
    <div  className='your-cart'>
    <h1 data-aos="fade-right" className='buy-title'>Your <span className='bold'> Cart </span></h1>
    <p  data-aos="fade-right" className='buy-slogan'>Customize your cart</p>

    <div  data-aos="fade-up" className='cart'>

    <div className='product-added'>
      <div className='image-cover'>
        <img src='jaropen.png' alt='added-product' className='added-product-image'></img>
      </div>
      <h3 className='added-product-name'> Fount Hair Butter</h3>
      <p className='added-product-price'> ${price}</p>
      <div className='adjustable-buttons'>
        <div onClick={handleDecreaseQuantity} className='reduce'>-</div>
        <h2 className='order-ammount'>{quantity}</h2>
        <div onClick={handleIncreaseQuantity} className='add'>+</div>
      </div>
    </div>


    </div>
    {showMaxReached && <p className='max'>{showMaxReached}</p>}
    <Link to='/' className='link-new'><p   className='cancel-order'> Cancel Order</p></Link>

    </div>
    <div  data-aos="fade-down" className='your-order'>
    <h1 className='order-title'> Your <span className='bold'>Order</span></h1>
   
    <div className='product-price'>
    <div className='titles'>
      <h4> Product</h4>
      <h4> Price</h4>
    </div>
    <div className='products-added'>
      <div className='product-added-information'>
        <p>Fount Hair Butter</p>
        <p>${price}</p>

      </div>
      <div className='product-added-quantity'>
        <p>Quantity</p>
        <p>x{quantity}</p>
      </div>

    </div>
    <div className='product-added-cost'>
        <p>Cost</p>
        <p>${cost}</p>
      </div>
      <div className='product-shipping-cost'>
      <p>Shipping</p>
        <p>{shipping}</p>
      </div>
      <div className='product-total-cost'>
      <p className='total'>total</p>
        <p className='total-price'>${finalAmmount.toFixed(2)}</p>
      </div>

   </div>
  <button onClick={handleCheckout} className='continue-to-payment'>Continue to payment</button> 



    </div>

    </div>
  )
}

export default BuyPage