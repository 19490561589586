import React, {useEffect, useState} from 'react'
import MobileHeader from '../components/MobileHeader'
import Aos from 'aos';
import 'aos/dist/aos.css'
function MobilePurchasing() {
  useEffect(()=>{
    Aos.init({ duration: 2000})
  })

  const [quantity, setQuantity] = useState(1);
  const [finalAmmount, setfinalAmmount] = useState(0);
  const [showMaxReached, setShowMaxReached] = useState("");
  const [shipping, setShipping] = useState(0);
  const [price, setPrice] = useState(15)
  const [cost, setCost] = useState(0);
    // eslint-disable-next-line 
    const [shippingAddress, setShippingAddress] = useState({
      name: '',
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    });
  
    const itemName = "Fount Hair Butter";
  
    const handleIncreaseQuantity = () => {
      if (quantity < 3) {
        setQuantity(quantity + 1);
      } else {
        setShowMaxReached("Max Amount Reached");
        setTimeout(() => {
          setShowMaxReached(false);
        }, 4000);
      }
    };
  
    const handleDecreaseQuantity = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };
  
  
   useEffect(()=>{
    const calculatePrice = () =>{
      
      if(quantity === 1){
        setShipping(6.73)
        setPrice(15)
        setCost(15)
      
      }
      if(quantity === 2){
        setShipping(11.98)
        setCost(25)
        setPrice(12.50)
  
      }
      if(quantity === 3){
        setShipping(14.73)
        setCost(34.80)
        setPrice(11.60)
      }
      setfinalAmmount(cost + shipping);
    }
    calculatePrice();
   },[quantity, cost, shipping])
  
   const handleCheckout = async () => {
        try{
          const res = await fetch("https://fount.onrender.com/create-checkout-session", {
            method: 'POST',
            headers:{
              'Content-Type':'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            mode:'cors',
            body:JSON.stringify({
              items:[
                {
                  id:1,
                  quantity: quantity,
                  name: itemName
                }
              ],
              shippingAddress: shippingAddress,
  
            })
          })
          const data = await res.json();
          window.location = data.url;
        }
        catch(error){
          console.log(error);
        }
    
  };
  
  return (
    <div data-aos="fade-up" className='mobile-purchasing'>
      <MobileHeader />
      <div className='product-mobile-title'>
      <h1>Hair Butter</h1>
      </div>
      <div className='circular-circle'>
      <div className='circle-mobile'></div>
      <img src='jaropen.png' className='product-mobile-open' alt='jar'></img>
      </div>
      <div className='add-decrease'>
      <div onClick={handleDecreaseQuantity} className='add-mobile'>
        -
      </div>
      <h1 className='count-mobile'>{quantity}</h1>
      <div onClick={handleIncreaseQuantity} className='decrease-mobile'> + </div>
      </div>

      <div className='rundown'>
        <div className='rundown-amount'> <p>Price</p> <p>${price}</p></div>
        <div className='rundown-amount'> <p>Quantity</p> <p>{quantity}</p></div>
        <div className='rundown-amount'> <p>Shipping</p> <p>${shipping}</p></div>
        <div className='rundown-amount'> <p>Total</p> <p>${finalAmmount.toFixed(2)}</p></div>
      </div>
{showMaxReached && <p className='max'>{showMaxReached}</p>}
      <div className='mobile-buy-button-div'>
        <button onClick={handleCheckout} className='mobile-buy-button'> Buy Now (${finalAmmount.toFixed(2)})</button>

      </div>

    
    </div>
  )
}

export default MobilePurchasing