import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackendHeader from '../components/BackendHeader';
import BackendNavbar from '../components/BackendNavbar';


function Accounts() {
const [data, setData] = useState([]);
const [error, setError] = useState('');
useEffect(()=>{
  const authentication = localStorage.getItem('token');
  if(!authentication){
    window.location.href = '/back-login'
  }
}, [])

useEffect(()=>{
fetchData()
}, [])
const fetchData = async() =>{
  const response = await fetch('https://fount.onrender.com/get-users');
  if(!response.ok){
    setError('error fetching data')
    return
  }
  else{
    const jsonData = await response.json();
    setData(jsonData);
  }
}


 

return (
  <div className='product-page'>
    <BackendHeader />
    <BackendNavbar />
    <h2>Accounts</h2>
    <div className='table-transaction'>
      <div className='table-header'>
        <p className='table-stuff'>Name</p>
        <p className='table-stuff'>Email</p>
      </div>
      {data.length === 0 ?(
        <p className='table-stuff-low'>No data available</p>

      ):(data.map((entry, index)=>(
          <Link to={`/account-details/${entry.email}`} className='table-info'>  <div key={index}  className='table-info'>
            <p className='table-stuff-low'>{entry.Name}</p>
            <p className='table-stuff-low'>{entry.email}</p>
          </div>
          </Link>
      ))
      )}
      {error && <p className='error'>{error}</p>}

    </div>
  </div>
);
        };


export default Accounts;
