import React, { useState, useEffect } from 'react';
import { useMemo } from 'react';
import { useTable } from 'react-table';
import BackendHeader from '../components/BackendHeader';
import BackendNavbar from '../components/BackendNavbar';
import { Link } from 'react-router-dom';

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  useEffect(()=>{
    const authentication = localStorage.getItem('token');
    if(!authentication){
      window.location.href = '/back-login'
    }
  }, [])

  useEffect(() => {
    fetchTransactions();
  }, 
  // eslint-disable-next-line 
  []);

  const fetchTransactions = async () => {
    try {
      const response = await fetch('https://fount.onrender.com/all-transactions');
      if (response.ok) {
        const data = await response.json();
        setTransactions(data);
      } else {
        throw new Error('Failed to fetch transactions');
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const data = useMemo(() => transactions, [transactions]);
  const columns = useMemo(() => [
    {
      Header: "Customer Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Total",
      accessor: "totalAmount"
    },
    {
      Header: "Shipping",
      accessor: "shippingAddressString"
    },
    {
      Header: "Status",
      accessor: "status"
    },
  ], []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div className='product-page'>
      <BackendHeader />
      <BackendNavbar />
      <h2> Transactions</h2>
      <div className='container'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className='column'>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='overflow' {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => (
                    <td className='row' {...cell.getCellProps()}>
                      {cell.column.id === 'name' ? (
                        <Link className='link-new' to={`/transaction-detail/${row.original.id}`}>
                          {cell.render("Cell")}
                        </Link>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='create-new'>
        <Link className='link' to='/new-product'> <button className='add-product'>Add product +</button></Link>
      </div>
    </div>
  );
}

export default Transactions;
