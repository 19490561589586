import React, { useState } from 'react'
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'
function BackendLogin() {
  const[email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  useEffect(()=>{
    Aos.init({ duration: 2000})
  })
  const handleLogin = async(e) =>{
    e.preventDefault();
    console.log(email)
    console.log(Password)
   const response = await fetch('https://fount.onrender.com/login-backend', {
    method: 'POST', 
    body: JSON.stringify({email, Password}),
    headers:{
      'Content-Type':'application/json',
      'Access-Control-Allow-Origin': '*'
    }
   })
   const data = await response.json();
   if(!response.ok){
    alert(data.message);
    return
   }
   else{
    localStorage.setItem('token', data.token)
    alert(data.message)
    window.location.href='/transactions'
   }
   
  }
  return (
    <div className='signup-container'>
  <div data-aos="fade-in" className='top-slogan'>
      <h1 className='signup-title'>Start Working <span className='bold'> Make a difference</span></h1>
      <p className='signup-slogan'>The backend user login</p>
      </div>
      <form onSubmit={handleLogin} data-aos='fade-up'  className='signup-form'>
        <h1 className='signup'> Login</h1>
        
        <div className='input-field'>
        <label className='label'>Email</label>
        <input onChange={(e) => setEmail(e.target.value)} className='input-signup'></input>
        </div>
        <div className='input-field'>
        <label className='label'>Password</label>
        <input onChange={(e) => setPassword(e.target.value)} type='password' className='input-signup'></input>
        </div>
        <button type='submit' className='signup-button'>Login</button>
      </form>
      <img data-aos='fade-down'  src='jarbackground.JPG' className='jar-background' alt='jar-background'></img>
    </div>
  )
}

export default BackendLogin