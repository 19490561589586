import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackendHeader from '../components/BackendHeader';
import BackendNavbar from '../components/BackendNavbar';

function MessageDetails() {
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const[email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [success, setSuccess] = useState('');
    const { id } = useParams();

    useEffect(()=>{
      const authentication = localStorage.getItem('token');
      if(!authentication){
        window.location.href = '/back-login'
      }
    }, [])

    useEffect(() => {
        fetchMessage();

        // eslint-disable-next-line 
    }, [id]);

    const fetchMessage = async () => {
        try {
            const message = await fetch(`https://fount.onrender.com/message/${id}`);
            if (!message.ok) {
                throw new Error('Error fetching data');
            } else {
                const jsonData = await message.json();
                setData([jsonData]); // Wrap jsonData in an array
                setEmail(jsonData.email);
            }
        } catch (error) {
            setError('Error fetching data');
        }
    };

    const sendEmail = async(e) =>{
      e.preventDefault();
      if(!email || !subject || !body){
        setError('please all fields')
        setTimeout(() =>{
          setError('')
        }, 2000)
        return;
      }
      else{
        const emailData = {
          email: email, 
          subject: subject,
          body: body
        }
        const response = await fetch('https://fount.onrender.com/send-reply', {
          method: 'POST', 
          body: JSON.stringify(emailData),
          headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        })
        const data = await response.json();
        if(!response.ok){
          setError(data.message);
        }
        else{
          setSuccess(data.message)
        }
      }
    }

    return (
        <div className='product-page'>
            <BackendHeader />
            <BackendNavbar />
            <h2>Message Details</h2>
            <div className='table-transaction'>
                {data.length === 0 ? (
                    <h3>No Data Available Sorry</h3>
                ) : (
                    data.map((entry, index) => (
                        <div key={index} className='message-information'>
                            <h3>{entry.firstName} {entry.lastName}</h3>
                            <p>{entry.email}</p>
                            <div className='message-row'>
                                <h3>Message:</h3>
                                <p className='message-actual'>{entry.questions}</p>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <form onSubmit={sendEmail} className='reply-form'>
              <h2> Send A Reply</h2>
                <label className='form-label'>Subject</label>
                <input onChange={(e)=>setSubject(e.target.value)} className='subject'></input>
                <label className='form-label'>Message</label>
                <textarea onChange={(e)=>setBody(e.target.value)} className='body'></textarea>
                <div className='button-div'>
                <button className='send-button' type='submit'>Send Response</button>

                </div>
            </form>
            {error && <p className='error'>{error}</p>}
            {success && <p className='success'>{success}</p>}

        </div>
    );
}

export default MessageDetails;
