import { useAuthContext } from './useAuthContext'

export const useLogin =  () => {

  const { dispatch } = useAuthContext()

  const login = async (email, Password) => {


    const response = await fetch('https://fount.onrender.com/login-user', {
      method: 'POST',
      headers: {'Content-Type': 'application/json',  'Access-Control-Allow-Origin': '*'},
      body: JSON.stringify({ email, Password })
    })
    const json = await response.json()

    if (!response.ok) {
      alert(json.message);
      return
    }
    if (response.ok) {
      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(json))

      alert('login success') 
      // update the auth context
      dispatch({type: 'LOGIN', payload: json})

      window.location.href='/buy'

      // update loading state
    }
  }

  return { login }
}