import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BackendHeader from '../components/BackendHeader';
import BackendNavbar from '../components/BackendNavbar';


function AccountDetails() {
const [data, setData] = useState([]);
const[error, setError] = useState('');
const {email} = useParams();

useEffect(()=>{
  const authentication = localStorage.getItem('token');
  if(!authentication){
    window.location.href = '/back-login'
  }
}, [])

useEffect(()=>{
fetchTransactions()
}, 
// eslint-disable-next-line
[])
const fetchTransactions = async() =>{
  const response = await fetch(`https://fount.onrender.com/transactions/${email}`);
  if(!response.ok){
    setError('error fetching data');
    return
  }
  else{
    const jsonData = await response.json();
    setData(jsonData)
  }
}



 

return (
  <div className='product-page'>
    <BackendHeader />
    <BackendNavbar />
    <h2>Accounts</h2>
    <div className='table-transaction'>
      <div className='table-header'>
        <p className='table-stuff'>Name</p>
        <p className='table-stuff'>Email</p>
        <p className='table-stuff'>Shipping</p>
        <p className='table-stuff'>Total</p>
        <p className='table-stuff'>Date</p>

      </div>
      {data.length === 0 ? (
        <p className='table-stuff-low'>No data found</p>
      ):(data.map((entry, index) =>(
          <Link className='table-info'>  <div key={index} className='table-info'>
            <p className='table-stuff-low'>{entry.name}l</p>
            <p className='table-stuff-low'>{entry.email}</p>
            <p className='table-stuff-low'>{entry.shippingAddressString}</p>
            <p className='table-stuff-low'>${entry.totalAmmount}</p>
            <p className='table-stuff-low'>{entry.createdAt}</p>


          </div>
          </Link>
    
      ))
)}
{error && <p className='error'>{error}</p>}
    </div>
  </div>
);
        };


export default AccountDetails;
