import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Signup from './pages/Signup';
import Login from './pages/Login';
import './App.css';
import BuyPage from './pages/BuyPage';
import Completion from './pages/Completion';
import Transactions from './pages/Transactions';
import TransactionDetail from './pages/TransactionDetail';
import Messages from './pages/Messages';
import MessageDetails from './pages/MessageDetails';
import Accounts from './pages/Accounts';
import AccountDetails from './pages/AccountDetails';
import BackendLogin from './pages/BackendLogin';
import MobileLanding from './pages/MobileLanding';
import MobilePurchasing from './pages/MobilePurchasing';


function App() {
 
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/buy' element={<BuyPage />} />
          <Route exact path='/completion' element={<Completion />} />
          <Route exact path='/transactions' element={<Transactions />} />
          <Route exact path='/transaction-detail/:id' element={<TransactionDetail />} />
          <Route exact path='/messages' element={<Messages />} />
          <Route exact path='/message-details/:id' element={<MessageDetails/>} />
          <Route exact path='/accounts' element={<Accounts />} />
          <Route exact path='/account-details/:email' element={<AccountDetails />} />
          <Route exact path='/back-login' element={<BackendLogin />} />
          <Route exact path='/mobile' element={<MobileLanding />} />
          <Route exact path='/mobile-purchase' element={<MobilePurchasing />} />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

