import React from 'react'

function MobileHeader() {
  return (
    <div className='mobile-header'>
      <div className='logo-mobile'>
      <img src='blacklogo.png' className='logo-mobile-png' alt='logo-mobile'></img>
      </div>
    </div>
  )
}

export default MobileHeader