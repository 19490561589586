import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import Aos from 'aos';
import 'aos/dist/aos.css'
import BackgroundImage from '../components/BackgroundImage'


function Main() {
  const [screenSize, setScreenSize] = useState(getScreenSize());
const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [email, setEmail] = useState('');
const [questions, setQuestions] = useState('');
const [error, setError] = useState('');
const [success, setSuccess] = useState('');

useEffect(() => {
  Aos.init({ duration: 2000 });

  const handleResize = () => {
    const newSize = getScreenSize();
    if (newSize !== screenSize) {
      setScreenSize(newSize);
    }
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, [screenSize]);

useEffect(() => {
  if (screenSize <= 500) {
    // Redirect logic for small screen
    window.location.href = '/mobile';
  } 
}, [screenSize]);

function getScreenSize() {
  return window.innerWidth;
}
  useEffect(()=>{
    let review = document.querySelectorAll(".review");
    let reviewIndex = 0;
    function changeReview(){
      review[reviewIndex].classList.remove("showing");
      reviewIndex++;
      if(reviewIndex >= review.length){
        reviewIndex = 0;
      }
      review[reviewIndex].classList.add("showing")
    }
    setInterval(changeReview, 5000)
  }, [])
  useEffect(()=>{
    let image = document.querySelectorAll(".images");
    let imageIndex = 0;
    function changeImage(){
      image[imageIndex].classList.remove("showimages");
      imageIndex++;
      if(imageIndex >= image.length){
        imageIndex = 0;
      }
      image[imageIndex].classList.add("showimages")
    }
    setInterval(changeImage, 3000)
  }, [])

  const submitMessage = async(e) =>{
    e.preventDefault();
if(!firstName || !lastName || !email || !questions){
  setError('please fill out all feilds')
  return;
}
    const requestBody = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      questions: questions
    }
    const response = await fetch('https://fount.onrender.com/send-message', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type":'application/json'
      }

    })
    const data = await response.json()
    if(!response.ok){
      setError(data.error)
      setTimeout(()=>{
        setError('');
      },2000)
      return
    }
    if(response.ok){
      setSuccess(data.message)
      setTimeout(()=>{
        setFirstName('');
        setLastName('');
        setEmail('');
        setQuestions('');
        setSuccess('')
      },2000)
    }
  }
const handleBuy = () =>{

  window.location.href = '/buy'
}

const scrolltoSection = (sectionID) =>{
  const section = document.getElementById(sectionID);
  if(section){
    section.scrollIntoView({ behavior: 'smooth' });
  }

}


  return (
    <div>
      <MainHeader />
     <BackgroundImage />
     <div className='landing-info'>
      <div className='title-box'>
        <h1 data-aos="fade-in" className='slogan'>"I Fount It"</h1>
        <p data-aos="fade-in" className='info'>Experience the transformative power of Fount, where faith meets wellness. Our company is built on the principles of God and Jesus, embracing the profound impact of 100% all-natural and organic products. Elevate your well-being and embrace a journey of spiritual and physical harmony with Fount </p>

      </div>
      <div data-aos="fade-right" className='action-button'>
        <button onClick={()=>scrolltoSection('our-products')} className='view-all-button' > View All Available Products 
        <svg className='right-arrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        </button>
      </div>
   </div>

     <div id='about-us' className='about-us-page'>
      <div>
      <h1 data-aos='fade-right' className='about-us'><span className='bold'>A Business led </span> by Our heartfelt <span className='bold'>connection With Christ </span></h1>
      <div  data-aos='fade-right' className='milestones'>
        <div  className='milestone-title'>
        <svg className='flag' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24V64 350.5 400v88c0 13.3 10.7 24 24 24s24-10.7 24-24V388l80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52V24zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8V334.7l-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5v-237z"/></svg>
        <h3> Key Milestones</h3>
        </div>
        <p className='milestones-info'>From the successful sell-out of our first product, the Hair Butter, we've continued to grow, eagerly anticipating the wants of achieving new milestones.</p>
      </div>
      <div  data-aos='fade-right' className='milestones'>
        <div className='milestone-title'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='flag'><path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"/></svg>
        <h3> A Fount Anecdote</h3>
        </div>
        <p className='milestones-info'>Our years in the making are a testament to allowing God to guide us, initially aspiring to create clothes but ultimately finding purpose in supporting others' wellness journeys.</p>
      </div>
      </div>
      
      
      <div data-aos="fade-in" className='gordon-family'>
        <div>
        <img data-aos="fade-down" src='family.png' className='family' alt='family'></img>
        </div>
        <div className='about-text'>
          <p data-aos="fade-up">The journey of Fount began as a family dream, with James Gordon (III) initiating the vision in 2008 under the brand "I Fear God." Years later, in 2015, Noah Gordon, fueled by ambition, took the brand forward. Today, we stand united as a family, with Fount as our middle name, symbolizing our commitment to family values and God's timing.</p>
        </div>
      </div>
     </div>


     <div className='whatwedo'>
      <div className='whatwedo-info'>
      <h3 data-aos="fade-in" className='whatwedo-title'> What We <span className='bold'> Do?</span></h3>
      <p data-aos="fade-up" className='whatwedo-slogan'>Fount is not just a business; it's a <span className='bold'>family-based journey </span> shaped by the <span className='bold'>faith and perseverance </span> of the Gordon family—James(III), Molly, James(IV) and Noah.</p>
      </div>
    <div className='whatwedo-additional-info'>
      <div data-aos="fade-up" data-aos-delay="300" className='whatwedo-box'>
        <h3 className='whatwedo-box-title'> Faith and wellness</h3>
        <p className='whatwedo-box-info'>Our heartfelt connection with Christ led us to seek wellness through nature's blessings. Facing health challenges within the family, we turned to God's provisions in the form of natural foods and products, laying the foundation for Fount's commitment to holistic well-being.</p>
      </div>
      <div data-aos="fade-up" data-aos-delay="600" className='whatwedo-box'>
        <h3 className='whatwedo-box-title'> Why Fount is effective</h3>
        <p className='whatwedo-box-info'>Fount's products are a result of years of personal use, boasting all-natural, oil & butter-based formulations. Our products promote hair growth, moisture, collagen production, healthy cell growth, and shiny hair. They increase elasticity, strengthen, improve blood flow to the scalp, and tackle frizz and breakage.</p>
      </div>
      <div data-aos="fade-up" data-aos-delay="900" className='whatwedo-box'>
        <h3 className='whatwedo-box-title'>The No-Chemical Approach</h3>
        <p className='whatwedo-box-info'> In a world filled with harmful chemicals, Fount stands out by ensuring that our products are 100% natural. We prioritize health above all else, offering a chemical-free alternative for your well-being.</p>
      </div>

    </div>
    <img data-aos="fade-in" src='jarbackground.jpg' className='lowerbackground' alt='lowerbackground'></img>
     </div>

    <div id='our-products' className='ourproducts'>
    <div className='ourproducts-title-div'>
    <h1  data-aos="fade-in" className='ourproducts-title'> Our <span className='bold'>Products</span></h1>
    </div>

    <div className='scroll'>
      


    <div className='ourproducts-box'>
    <div  data-aos="fade-up" className='ourproducts-image-box'>
    <div className='outline-div'>
      <img src='jaropen.png' className='productimage' alt='productimage'></img>
    </div>
    <div data-aos="fade-up"  className='purchase-buttons'>
     <button onClick={handleBuy} className='buynow'>Buy Now</button>
      <button onClick={handleBuy} className='addtocart'>Add to Cart</button>
    </div>
    </div>
    <div className='ourproducts-product-info-box'>
     
        <h1   data-aos="fade-in"className='product-title'><span className='bold'>Hair </span> Butter  <span className='bold-small'>$15 </span> </h1>
        <ul data-aos="fade-up" className='product-info'>
        <li>Promotes Hair Growth</li>
        <li>100% Natural</li>
        <li>Oil & Butter Based</li>
        <li>Promotes Moisture</li>
        <li>Supports Collagen Production</li>
        <li>Promoting healthy cell growth and shiny hair</li>
    </ul>
    
    </div>
    </div>




    </div>

    </div>


  <div id='testimonials' className='testimonials'>
    <h1 data-aos="fade-right" className='titleone'> Customer</h1>
    <h1 data-aos="fade-right" className='titletwo'> Testimonials</h1>
    <h3 data-aos="fade-right" className='test-slogan'> See what our customers have to say</h3>
<div className='testimonials-box'>
  <div data-aos="fad-down" className='text-side'>
    <div className='showing review'>
<img src='stars.png' className='stars' alt='stars'></img>
<p >"Fount is a beacon of authenticity in the sea of beauty products. Their commitment to being chemical-free aligns with my wellness journey. The products speak for themselves – my hair has never felt this healthy. Proud to be a part of the Fount family!" 
  <span className='bold'>Stephnie F., Illinois</span></p>
  </div>

  <div className='review'>
<img src='stars.png' className='stars' alt='stars'></img>
<p >"I stumbled upon Fount when looking for natural hair care, and it's been a game-changer. The Gordon family's story resonates with me, and using their products feels like a spiritual journey for my hair and well-being. The results are amazing, and I'm proud to support such a genuine, faith-driven brand."

  <span className='bold'>Johna B., New York:</span></p>
  </div>

  <div className='review'>
<img src='stars.png' className='stars' alt='stars'></img>
<p >"Fount is not just about products; it's about a family's commitment to well-being. I've tried countless hair products, but Fount stands out with its natural ingredients and the love poured into each creation. My hair feels revived, and I appreciate supporting a brand that prioritizes health without compromise."
  <span className='bold'>Jimmy I., Indiana:</span></p>
  </div>

  <div className='review'>
<img src='stars.png' className='stars' alt='stars'></img>
<p > "Fount is a true blessing! The Hair Butter not only transformed my locks but also nourished my soul. Knowing it's made by a family with deep faith in every jar makes it extra special. My hair has never been healthier, and I feel the positive energy infused in each product."
  <span className='bold'>Beverly L., California</span></p>
  </div>

  <div className='review'>
<img src='stars.png' className='stars' alt='stars'></img>
<p > "Fount is my go-to for natural hair care. The Hair Butter is a miracle worker, and the fact that it's made by a family who turned their struggles into strength resonates with me. It's more than just a purchase; it's a connection to faith, wellness, and genuine care for customers."

  <span className='bold'>Mike W., Georgia</span></p>
  </div>


  </div>
  <div data-aos="fade-in" direction='up' speed={30} className='image-side'>
     
     <div className='images'>
       <img src='customerone.jpeg' className='customer' alt='customer'></img>
       </div>
     <div className="images">
     <img src='customer2.jpeg' className='customer' alt='customer'>
      </img>
     </div>
     <div className="images">
     <img src='customer3.JPG' className='customer' alt='customer'>
      </img>
     </div>
      
  </div>

</div>
<img data-aos="fade-up" src='jarbackground.JPG' className='testimonial-background' alt='testimonial-background'></img>
  </div>


  <div id='contact-us' className='contact-us'>
    <h1 data-aos="fade-up" className='contact-title'> Have <span className='bold'>Questions?</span></h1>
    <h3 data-aos="fade-up" className='contact-slogan'> Feel free to reach out to us. We will try to respond within 24 hours</h3>

    <form onSubmit={submitMessage} data-aos="fade-up" className='contact-form'>
      <div className='name'>
        <input onChange={(e)=>setFirstName(e.target.value)} value={firstName} className='name-input' placeholder='First Name'></input>
        <input onChange={(e)=>setLastName(e.target.value)} value={lastName} className='name-input' placeholder='Last Name'></input>
      </div>
      <input onChange={(e)=>setEmail(e.target.value)} value={email} className='email' placeholder='Email'></input>
      <textarea onChange={(e)=>setQuestions(e.target.value)} value={questions}  className='textarea' placeholder='Enter Questions Here'></textarea>
      <button type='submit' className='submit-button'> Send Message</button>
    </form>
   { error && <h3 className='error'>{error}</h3>}
   { success && <h3 className='success'>{success}</h3> }

    <div className='contact-footer'>
    <p> check out our social media</p>
    <div className='social-media-images'>
      <img src='facebooklogo.png' className='social' alt='social'></img>
      <img src='instagramlogo.png' className='social' alt='social'></img>
      <img src='gmaillogo.png' className='social' alt='social'></img>
    </div>
    </div>
  
  <img data-aos="fade-in" src='jarbackground.JPG' className='contact-background' alt='contact-background'></img>

  </div>


    </div>
  )
}

export default Main