import React, {useEffect, useState} from 'react'
import MobileHeader from '../components/MobileHeader'
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
function MobileLanding() {

  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {
    Aos.init({ duration: 2000 });
  
    const handleResize = () => {
      const newSize = getScreenSize();
      if (newSize !== screenSize) {
        setScreenSize(newSize);
      }
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenSize]);
  
  useEffect(() => {
    if (screenSize > 500) {
      // Redirect logic for small screen
      window.location.href = '/';
    } 
  }, [screenSize]);
  
  function getScreenSize() {
    return window.innerWidth;
  }
  const scrolltoSection = (sectionID) =>{
    const section = document.getElementById(sectionID);
    if(section){
      section.scrollIntoView({ behavior: 'smooth' });
    }
  
  }
  return (
  
    <div className='mobile-landing'>
        <MobileHeader />
        <div className='title'>
          <h1 data-aos='fade-in'> "I Fount It"</h1>
        </div>
        <p data-aos='fade-right' className='text-mobile'>Fount is not just a business; it's a family-based journey shaped by the faith and perseverance of the Gordon family—James (III), Molly, James (IV) and Noah. </p>
        <div className='buy-button-mobile-div'>
          <button onClick={()=>scrolltoSection('our-product')} data-aos='fade-in' className='buy-button-mobile'>
            View Products
          </button>

        <div className='milestones-div'>
          <div data-aos='fade-right' className='one'>
          <svg xmlns="http://www.w3.org/2000/svg" className='flag-new' viewBox="0 0 448 512"><path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32V64 368 480c0 17.7 14.3 32 32 32s32-14.3 32-32V352l64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48V32z"/></svg>
          <p className='info-flag'>From the successful sell-out of our first product, the Hair Butter, we've continued to grow, eagerly anticipating the wants of achieving new milestones.</p>
          </div>
          <div data-aos='fade-right' className='one'>
          <svg xmlns="http://www.w3.org/2000/svg" className='quote' viewBox="0 0 448 512"><path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"/></svg>          </div>
          <p className='info-flag' data-aos='fade-right'>Our years in the making are a testament to allowing God to guide us, initially aspiring to create clothes but ultimately finding purpose in supporting others' wellness journeys.</p>

          </div>
        </div>
        <div id='our-product' className='our-product-mobile-div'>
        <h1 data-aos='fade-up'> Our Product</h1>
        <img data-aos='fade-up' src='jaropen.png' className='jar-mobile' alt='jar'></img>
        <h3> Hair Butter</h3>
        <ul data-aos='fade-right' className='info-mobile-pro'>
          <li> Promotes Hair Growth </li>
          <li>100% Natural</li>
          <li>Oil & Butter Based</li>
          <li>Promotes Moisture</li>
          <li>Supports Collagen Production</li>
          <li>Promoting healthy cell growth and shiny hair</li>
        </ul>
        <Link className='link' to='/mobile-purchase'><button  className='mobile-buy'>
        $15 Buy Now
        </button>
        </Link>
        </div>

        <div  className='testimony-mobile-div'>
        <h1 data-aos='fade-right'> Testimonies</h1>
        <p data-aos='fade-right' className='test'>""Fount is not just about products; it's about a family's commitment to well-being. I've tried countless hair products, but Fount stands out with its natural ingredients and the love poured into each creation. My hair feels revived, and I appreciate supporting a brand that prioritizes health without compromise." "</p>
        <h3 data-aos='fade-up'>Jimmy I., Indiana</h3>

        <p data-aos='fade-right' className='test'>"I stumbled upon Fount when looking for natural hair care, and it's been a game-changer. The Gordon family's story resonates with me, and using their products feels like a spiritual journey for my hair and well-being. The results are amazing, and I'm proud to support such a genuine, faith-driven brand."
</p>
        <h3 data-aos='fade-up'>Johna B., New York</h3>
        <p data-aos='fade-right' className='test'>"Fount is my go-to for natural hair care. The Hair Butter is a miracle worker, and the fact that it's made by a family who turned their struggles into strength resonates with me. It's more than just a purchase; it's a connection to faith, wellness, and genuine care for customers."
</p>
        <h3 data-aos='fade-up'>Mike W., Georgia</h3>
        </div>
    </div>
  )
}

export default MobileLanding