import React from 'react'
import { Link } from 'react-router-dom'

function BackendNavbar() {
  const logout = () =>{
    localStorage.clear();
    window.location.href='/back-login'
  }
  return (
    <div className='backendNavbar'>
      <div className='upper'>
       <Link to='/transactions' className='options-upper'> <p className='options-upper'> Transactions</p> </Link>
       <Link to='/accounts' className='options-upper'>  <p className='options-upper'> Accounts</p> </Link>
        <Link to='/messages' className='options-upper'><p className='options-upper'> Messages</p> </Link>
      </div>
      <div className='lower'>
        <p onClick={logout} className='options-upper'> Logout</p>
      </div>


    </div>
  )
}

export default BackendNavbar