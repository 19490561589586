import React from 'react'
import BuyHeader from '../components/BuyHeader'
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
function Completion(props) {
  useEffect(()=>{
    Aos.init({ duration: 2000})
  })
  return (
    <div className='complete'>
      <BuyHeader />
      <h1 data-aos="fade-down" className='thanks'> Thank <span className='bold'>You</span></h1>
      <p data-aos="fade-in">for shopping with Fount!</p>
      <img data-aos="fade-up" src='checkmark.png' className='checkmark' alt='checkmark'></img>
      <p data-aos="fade-in" className='order'>Your order is being processed. You will be updated through the provided email</p>
     <Link to='/' style={{textDecoration: "underline", cursor:"pointer", marginTop:"10px", color:'black'}}> <p data-aos="fade-in" className='bold'>Take me home</p></Link>
    </div>
  )
}

export default Completion
