import React, { useState, useEffect } from 'react';

import BackendHeader from '../components/BackendHeader';
import { useParams } from 'react-router-dom';
import BackendNavbar from '../components/BackendNavbar';


function TransactionDetail() {

  const [name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [total, setTotal] = useState('');
  const [EAD, setEAD] = useState('');
  const [Status, setStatus] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [Shipping, setShipping] = useState('');

  const { id } = useParams();
  
  useEffect(()=>{
    const authentication = localStorage.getItem('token');
    if(!authentication){
      window.location.href = '/back-login'
    }
  }, [])

  useEffect(() => {
      

    fetchTransactionDetail();
    

  }, 
  // eslint-disable-next-line
  []);
      


  const fetchTransactionDetail = async () => {
    try {
      const response = await fetch(`https://fount.onrender.com/transaction/${id}`);
      if (response.ok) {
        const data = await response.json();
        setName(data.name);
        setEmail(data.email);
        setAddress(data.shippingAddressString);
        setTotal(data.totalAmount);     
        setNewStatus(data.status)   
      
      } else {
        throw new Error('Failed to fetch transaction detail');
      }
    } catch (error) {
      console.error('Error fetching transaction detail:', error);
    }
    
  };
  const handleSubmit = async(e) =>{
    e.preventDefault();
        const requestBody = {
      Email: Email,
      Status: Status,
      Shipping: Shipping,
      EAD: EAD,
    };
  

    try{
      const response = await fetch('https://fount.onrender.com/send-updates', {
        method: 'POST', 
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type':'application/json'
        }
      });
      const data = response.json();
      if(response.ok){
        alert('Status Updated')
        window.location.href = '/transactions'

      }
      if(!response.ok){
        alert(data.json)
      }
    }
    catch(error){
      console.log(error)
    }

  }
  
 

  return (
    <div className='product-page'>
      <BackendHeader />
      <BackendNavbar />
      <h2> Transactions</h2>
      <div className='table-transaction'>
        <div className='table-header'>
        <p className='table-stuff'>Name </p>
        <p className='table-stuff'> Email</p>
        <p className='table-stuff'>shipping</p>
        <p className='table-stuff'>total</p>
        <p className='table-stuff'>Status</p>

        </div>
        <div className='table-info'>
        <p className='table-stuff-low'>{name}</p>
        <p className='table-stuff-low'>{Email}</p>
        <p className='table-stuff-low'>{address}</p>
        <p className='table-stuff-low'>{total}</p>
        <p className='table-stuff-low'>{newStatus}</p>

        </div>

      </div>
      
      <form onSubmit={handleSubmit}  className='product-status'>
        <h3>Product Status</h3>
        <p>Product Status will be informed to the client through email</p>
        <div className='select-container'>
          <select onChange={(e)=> setStatus(e.target.value)} className='status' >
          <option>Select Status</option>
            <option value="Processing Order">Processing Order</option>
            <option value="Ready to ship">Ready to Ship</option>
            <option value="Shipped Product">Shipped Product</option>
          </select>
        </div>
       
          <div className='shipping-status'>
            <h3>Shipping Status</h3>
            <p>Enter Tracking Number</p>
            <input onChange={(e)=> setShipping(e.target.value)}  className='tracking' placeholder='Tracking Number'></input>
            <p>Expected Date of Arrival</p>
            <input onChange={(e)=> setEAD(e.target.value)} className='tracking' type='date' placeholder='MM/DD/YYYY'></input>
          </div>
     
        <button type='submit' className='add-product'>Update</button>
      </form>
    </div>
  );
}

export default TransactionDetail;
